import { request, noTimeOutReq } from '@/utils/request.js'

//获取考试信息列表
export function getExamInfoListApi (pageIndex, pageSize, data) {
  return request({
    url: `/exam/score/examInfo/${pageIndex}/${pageSize}/page`,
    method: 'post',
    data: data,
  })
}
