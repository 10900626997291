<!-- 普通角色评卷 -->
<template>
  <div>
    <el-row>
      <el-form :inline="true" :model="queryParam">
        <el-form-item label="考试状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.examStatus"
            placeholder="请选择考试状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examStatus in examStatusOptions"
              :key="examStatus.value"
              :label="examStatus.label"
              :value="examStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="补考状态" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subExamStatus"
            placeholder="请选择补考状态"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examStatus in subExamStatusOptions"
              :key="examStatus.value"
              :label="examStatus.label"
              :value="examStatus.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试科目" class="formItemBoxStyle">
          <el-select
            v-model="queryParam.subjectType"
            placeholder="请选择考试科目"
            clearable
            @change="reloadInit"
          >
            <el-option label="全部" value></el-option>
            <el-option
              v-for="examSubject in examSubjectOptions"
              :key="examSubject.value"
              :label="examSubject.label"
              :value="examSubject.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="考试名称" class="formItemBoxStyle">
          <el-input
            v-model="queryParam.examName"
            placeholder="请输入考试名称"
            clearable
            style="width: 200px"
            @input="reloadInit"
          ></el-input>
        </el-form-item>

        <el-form-item label="考试时间" class="formItemBoxStyle">
          <div class="block">
            <el-date-picker
              v-model="queryTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryDateChange"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button v-throttle type="primary" @click="init">查询</el-button>
          <el-button v-throttle @click="refresh">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row>
      <el-table
        v-loading="loading"
        border
        ref="examInfoTable"
        :data="examInfoList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
        default-expand-all
        row-key="examId"
        :tree-props="{ children: 'examInfoList' }"
      >
        <el-table-column
          prop="examName"
          label="考试名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="examStatus"
          label="考试状态"
          show-overflow-tooltip
          :formatter="customFormatter"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.examStatus == 2">等待考试</span>
            <span v-else-if="scope.row.examStatus == 3">正在考试</span>
            <span v-else-if="scope.row.examStatus == 4">考试完成</span>
            <span v-else-if="scope.row.examStatus == 5">等待补考</span>
            <span v-else-if="scope.row.examStatus == 6">正在补考</span>
            <span v-else-if="scope.row.examStatus == 7">考试终止</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="subjectTypes"
          label="考试科目"
          show-overflow-tooltip
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column
          prop="examStartTime"
          label="考试时间"
          show-overflow-tooltip
          :formatter="customFormatter"
        ></el-table-column>
        <el-table-column label="操作" min-width="50px" show-overflow-tooltip>
          <template slot-scope="scope" v-if="scope.row.existMarkTeacher">
            <el-button
              v-throttle
              size="small"
              type="primary"
              icon="el-icon-tickets"
              @click="marking(scope.row)"
              v-if="scope.row.markRuleStatus >= 1 && scope.row.examStatus > 2"
              >评卷</el-button
            >
            <el-button
              v-throttle
              size="small"
              type="primary"
              icon="el-icon-tickets"
              :disabled="true"
              v-if="scope.row.markRuleStatus < 1 || scope.row.examStatus <= 2"
              >评卷</el-button
            >
            <el-button
              v-throttle
              size="small"
              type="primary"
              icon="el-icon-document"
              @click="markingTest(scope.row)"
              :disabled="scope.row.examStatus <= 2"
              >试评卷</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { getExamInfoListApi } from '@/api/marking/markingPlainManage.js'
import { isEmpty, formatDate } from '@/utils/util'
import { setStore, removeStore } from '@/utils/token'
export default {
  name: 'markingManage',
  data() {
    return {
      loading: false,
      queryTime: '',
      queryParam: {
        examStatus: '',
        subjectType: '',
        examName: '',
        examStartTime: '',
        examEndTime: '',
        subExamStatus: '',
      },
      examInfoList: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      examStatusOptions: [
        // { label: '草稿', value: 1 },
        { label: '等待考试', value: 2 },
        { label: '正在考试', value: 3 },
        { label: '考试完成', value: 4 },
        /*  { label: '等待补考', value: 5 },
         { label: '正在补考', value: 6 },
         { label: '考试终止', value: 7 }, */
      ],
      subExamStatusOptions: [
        // { label: '草稿', value: 1 },
        // { label: '等待考试', value: 2 },
        // { label: '正在考试', value: 3 },
        { label: '等待补考', value: 5 },
        { label: '正在补考', value: 6 },
        { label: '考试完成', value: 4 },
        //  { label: '考试终止', value: 7 },
      ],
      examSubjectOptions: [
        { label: '物理', value: 1 },
        { label: '生物', value: 2 },
        { label: '化学', value: 3 },
      ],
    }
  },
  watch: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getExamInfoList()
    },
    getExamInfoList() {
      this.loading = true
      getExamInfoListApi(
        this.page.pageIndex,
        this.page.pageSize,
        this.queryParam
      )
        .then((res) => {
          if (res.success) {
            this.examInfoList = []
            this.examInfoList = res.data
            this.page.totalSize = 0
            this.page.totalSize = res.total
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    marking(row) {
      setStore('markExamInfo', JSON.stringify(row))
      setTimeout(() => {
        removeStore('markExamInfo')
      }, this.$expire)
      this.$router.push({
        path: `/marking/markingDetails`,
      })
    },
    markingTest(row) {
      setStore('markTestExamInfo', JSON.stringify(row))
      setTimeout(() => {
        removeStore('markTestExamInfo')
      }, this.$expire)
      this.$router.push({
        path: `/marking/markingTest`,
      })
    },
    queryDateChange(value) {
      if (value.length > 0) {
        this.queryParam.examStartTime = Date.parse(value[0])
        this.queryParam.examEndTime = Date.parse(value[1])
      }
      this.reloadInit()
    },
    edit() {},
    refresh() {
      this.queryParam = {
        examStatus: '',
        subjectType: '',
        examName: '',
        examStartTime: '',
        examEndTime: '',
      }
      this.queryTime = ''
      this.init()
    },
    reloadInit() {
      this.page.pageIndex = 1
      this.init()
    },
    handleSelectionChange() {},
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getExamInfoList()
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.getExamInfoList()
    },
    //格式化内容
    customFormatter(row, column) {
      if (isEmpty(row)) {
        return
      }
      switch (column.property) {
        case 'subjectTypes':
          if (row.subjectTypes <= 0) {
            return ''
          }
          let appendResult = ''
          row.subjectTypes.forEach((item, index) => {
            if (isEmpty(item)) {
              return
            }
            let examSubjectName = ''
            switch (item) {
              case 0:
                examSubjectName = '未知'
                break
              case 1:
                examSubjectName = '物理'
                break
              case 2:
                examSubjectName = '生物'
                break
              case 3:
                examSubjectName = '化学'
                break
            }
            appendResult += examSubjectName
            if (index < row.subjectTypes.length - 1) {
              appendResult += ','
            }
          })
          return appendResult
        case 'examStartTime':
          if (isEmpty(row.examStartTime)) {
            return '正在考试'
          }
          return formatDate(new Date(row.examStartTime), 'yyyy-MM-dd hh:mm:ss')
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
